<template>
  <v-row class="ma-0">
    <v-col cols="8">
      <v-row class="ma-0">
        <v-icon class="mr-3">$filter</v-icon>
        <v-chip-group
            v-model="params.storeFileCategoryId"
            active-class="primary"
            column
        >
          <v-chip
              v-for="category in categories"
              :key="category.value"
              :value="category.value"
          >
            {{ category.text }}
          </v-chip>
        </v-chip-group>
      </v-row>
      <FileBatches :index-request="fileBatchesIndexRequest" :params="params" ref="fileBatches"/>
    </v-col>
    <v-col cols="4">
      <h3 class="text-h3 text-uppercase">{{ $t('locationDetails.uploadTitle') }}</h3>
      <v-form @submit.prevent="uploadNewFiles" ref="form">
        <k-field-group language-prefix="locationDetails.fields">
          <k-radio-group :items="categories" field="storeFileCategoryId" v-model="form.storeFileCategoryId"/>
          <k-file-field field="files" v-model="form.files" :multiple="true" class="mb-3"/>
          <v-btn type="submit" color="black" tile depressed dark class="float-right" :loading="isLoading">
            {{ $t('actions.add') }}
          </v-btn>
        </k-field-group>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import storeFileCategories from '@/application/enums/storeFileCategories.js';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import { addFileBatch, index } from '@/modules/locationDetails/api/locationDetails.js';
import { mapGetters } from 'vuex';
import { EventBus } from '@/application/EventBus.js';
import FileBatches from '@/modules/locationDetails/components/FileBatches.vue';

export default {
  name: 'Documents',
  components: {
    FileBatches,
    KFileField,
    KFieldGroup,
    KRadioGroup,
  },
  data: () => ({
    form: {
      files: [],
      storeFileCategoryId: storeFileCategories.CONSTRUCTIONAL_DRAWINGS,
    },
    isLoading: false,
    params: {
      storeFileCategoryId: undefined,
    },
  }),
  computed: {
    ...mapGetters('settings', { storeId: 'getCurrentStoreId' }),
    categories() {
      return Object.keys(storeFileCategories).map(type => {
        return {
          value: storeFileCategories[type],
          text: this.$t(`locationDetails.storeFileCategories.${type}`),
        };
      });
    },
  },
  methods: {
    async uploadNewFiles() {
      try {
        this.isLoading = true;
        await addFileBatch(this.storeId, this.form.storeFileCategoryId, this.form.files);
        this.$refs.form.reset();
        this.$nextTick(() => {
          this.resetStoreFileCategory();
        })
        EventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('locationDetails.messages.filesAdded'),
        });
        this.$refs.fileBatches.reloadPaginator();
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultError'),
        });
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
    fileBatchesIndexRequest(...args) {
      return index(this.storeId, ...args);
    },
    resetStoreFileCategory() {
      this.form = {
        storeFileCategoryId: storeFileCategories.CONSTRUCTIONAL_DRAWINGS, //default category
        files: [],
      };
    },
  },
};
</script>

<style scoped>

</style>
