<template>
  <k-field-group language-prefix="locationDetails.commercialNotes.fields">
    <v-form @submit.prevent="save">
      <h3 class="text-h3">{{ $t('locationDetails.commercialNotes.storeDimensions') }}</h3>
      <v-row>
        <v-col cols="6">
          <KTextField field="area" v-model="values.area" suffix="m²"/>
          <KTextField field="length" v-model="values.length" suffix="m"/>
        </v-col>
        <v-col cols="6">
          <KTextField field="width" v-model="values.width" suffix="m"/>
          <KTextField field="height" v-model="values.height" suffix="m"/>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <VSpacer/>
        <v-btn color="black" dark tile type="submit">{{ $t('actions.save') }}</v-btn>
      </v-row>
    </v-form>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import { show, update } from '@/modules/locationDetails/api/storeDimensions.js';
import { mapGetters } from 'vuex';
import { StoreDimensions } from '@/application/models/StoreDimensions.js';
import { EventBus } from '@/application/EventBus.js';

export default {
  name: 'StoreDimensions',
  components: {
    KTextField,
    KFieldGroup,
  },
  watch: {
    currentStoreId() {
      this.fetch();
    },
  },
  computed: {
    ...mapGetters('settings', { currentStoreId: 'getCurrentStoreId' }),
  },
  data: () => ({
    values: new StoreDimensions(),
  }),
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const response = await show(this.currentStoreId);
      this.values.mapResponse(response.data.data);
    },
    async save() {
      await update(this.currentStoreId, this.values);
      EventBus.$emit('snackbar', {
        color: 'success',
        text: this.$t('actions.resourceUpdated', { resource: this.$t('locationDetails.commercialNotes.storeDimensions') }),
      });
    },
  },
};
</script>

<style scoped>

</style>
