<template>
  <page-layout>
    <template #header>
      <div class="mb-8">
        <k-title>{{ $t('locationDetails.pageTitle') }}</k-title>
      </div>
    </template>

    <template>
      <v-tabs
          v-model="tab"
          background-color="white"
          color="black"
          slider-color="primary"
      >
        <v-tab>
          {{ $t('locationDetails.tabs.documents') }}
        </v-tab>
        <v-tab>
          {{ $t('locationDetails.tabs.commercialNotes') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
            <Documents/>
        </v-tab-item>
        <v-tab-item>
            <CommercialNotes/>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import Documents from '@/modules/locationDetails/views/Documents.vue';
import CommercialNotes from '@/modules/locationDetails/views/CommercialNotes.vue';

export default {
  name: 'LocationDetails',
  components: { CommercialNotes, Documents, KTitle, PageLayout },
  data: () => ({
    tab: 0,
  })
};
</script>

<style scoped>
.v-tabs {
  width: max-content;
}
</style>
