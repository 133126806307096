<template>
  <div>
    <div>
      <v-btn ref="mainButton"
             v-bind="$attrs"
             v-on="$listeners"
             :disabled="disabled"
             :color="color"
             depressed
             tile
             :small="small"
             @click.stop="toggleMenuOnClick ? showMenu = !showMenu : null">
        <slot></slot>
      </v-btn>
      <v-btn ref="dropdown"
             :disabled="disabled"
             class="dropdown-button"
             :color="color"
             outlined
             tile
             :small="small"
             @click.stop="showMenu = !showMenu">
        <v-icon>$dropdown</v-icon>
      </v-btn>
    </div>
    <v-menu v-model="showMenu"
            :min-width="width"
            :position-x="x"
            :position-y="y"
            tile>
      <slot name="list"></slot>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'DropdownButton',
  inheritAttrs: false,
  data: () => ({
    showMenu: false,
    x: 0,
    y: 0,
    width: 0,
  }),
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    toggleMenuOnClick: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  watch: {
    /**
     * Determain the position, vuetify itself can't do this perfectly because it can only base this on one element and not
     * on the two buttons together.
     * @TODO i can't get this to work perfectly, when you are scrolled too far to the bottom, it overlaps the button itself.
     */
    showMenu() {
      const rect = this.$refs.mainButton.$el.getBoundingClientRect();
      this.x = rect.x;
      this.y = rect.top + this.$refs.mainButton.$el.offsetHeight;
      this.width = this.$refs.mainButton.$el.offsetWidth + this.$refs.dropdown.$el.offsetWidth;
    },
  },
};
</script>

<style scoped>
.dropdown-button {
  min-width: 40px !important;
}
</style>
