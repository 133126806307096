import { destroy, post } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const addFileBatch = (storeId, storeFileCategoryId, files) => post(`/store/${storeId}/file-batch`, {
  storeFileCategoryId,
  files,
});

export const index = (storeId, ...args) => getPaginated(`/store/${storeId}/file-batch`, ...args);

export const batch = (fileBatchId, ...args) => getPaginated(`file-batch/${fileBatchId}/file`, ...args);

export const removeFile = (fileBatchId, fileId) => destroy(`file-batch/${fileBatchId}/file/${fileId}`);

export const moveFileBulk = (fileBatchId, newStoreFileCategoryId, fileIds) => post(`file-batch/${fileBatchId}/files/move`, {
  fileIds,
  newStoreFileCategoryId,
});

export const removeFileBulk = (fileBatchId, fileIds) => destroy(`file-batch/${fileBatchId}/files`, { params: { fileIds } });
