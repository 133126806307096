<template>
  <v-dialog @click:outside="cancelMove" v-model="showDialog" width="500px">
    <v-sheet class="pa-2">
      <v-row class="ma-0">
        <div class="title">{{ $t('locationDetails.actions.move.confirmationBody', { selectedAmount }) }}</div>
        <VSpacer/>
        <v-btn icon small @click="cancelMove">
          <v-icon color="black">$close</v-icon>
        </v-btn>
      </v-row>
      <v-row class="ma-3">
        <v-col>
          <k-field-group language-prefix="locationDetails.fields">
            <KSelect v-model="categoryId"
                     :items="categories"
                     field="storeFileCategoryId"/>
          </k-field-group>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <VSpacer/>
        <v-btn outlined color="primary" @click="cancelMove" class="mr-2">{{ $t('actions.cancel') }}</v-btn>
        <v-btn color="primary" :disabled="categoryId < 1" @click="$emit('confirm-move', categoryId)">
          {{ $t('locationDetails.actions.move.confirmButtonText', { selectedAmount }) }}
        </v-btn>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import storeFileCategories from '@/application/enums/storeFileCategories.js';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';

export default {
  name: 'MoveFilesToCategoryModal',
  components: {
    KFieldGroup,
    KSelect,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    selectedAmount: {
      type: Number,
      default: 0,
      required: true,
    },
    currentStoreFileCategoryId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    categoryId: 0,
  }),
  computed: {
    categories() {
      return Object.keys(storeFileCategories).map(type => {
        return {
          value: storeFileCategories[type],
          text: this.$t(`locationDetails.storeFileCategories.${type}`),
        };
      }).filter(category => category.value !== this.currentStoreFileCategoryId);
    },
  },
  methods: {
    cancelMove() {
      this.categoryId = 0;
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>

</style>
