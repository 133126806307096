<template>
  <v-row class="ma-0">
    <v-col cols="6"></v-col>
    <v-col cols="6">
      <StoreDimensions/>
    </v-col>
  </v-row>
</template>

<script>
import StoreDimensions from '@/modules/locationDetails/components/StoreDimensions.vue';
export default {
  name: 'CommercialNotes',
  components: { StoreDimensions },
};
</script>

<style scoped>

</style>
