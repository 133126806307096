<template>
  <v-row class="mt-0 px-2" v-if="batch.length === 0">
    <v-col cols="2" v-for="i in 6" :key="i">
      <v-responsive :aspect-ratio="1">
        <VSkeletonLoader type="image" width="100%" height="100%"/>
      </v-responsive>
    </v-col>
  </v-row>
  <div v-else>
    <v-row class="pr-5">
      <v-col class="d-flex">
        <VSpacer/>
        <KBtn small @click="inSelectionMode = true" v-if="!inSelectionMode">
          {{ $t('locationDetails.actions.select.select') }}
        </KBtn>
        <template v-else>
          <KBtn small text @click="cancelSelectionMode" v-if="inSelectionMode">{{ $t('actions.cancel') }}</KBtn>
          <KBtn small text @click="deSelectAll" v-if="selectedItems.length === pagination.totalFiles">
            {{ $t('locationDetails.actions.select.deSelectAll') }}
          </KBtn>
          <KBtn small text @click="selectAll" v-if="selectedItems.length < pagination.totalFiles">
            {{ $t('locationDetails.actions.select.selectAll') }}
          </KBtn>
          <DropdownButton small outlined
                          :color="'primary'"
                          :toggle-menu-on-click="true">
            {{ $t('locationDetails.actions.select.filesSelected', { selectedAmount: selectedItems.length }) }}
            <template #list>
              <v-list>
                <v-list-item @click="openMoveItemsModal" :disabled="selectedItems.length === 0">
                  {{ $t('locationDetails.actions.move.move') }}
                </v-list-item>
                <v-list-item @click="openConfirmDeleteModal" :disabled="selectedItems.length === 0">
                  {{ $t('locationDetails.actions.delete.delete') }}
                </v-list-item>
              </v-list>
            </template>
          </DropdownButton>
          <MoveFilesToCategoryModal :show-dialog="showMoveDialog"
                                    :selected-amount="selectedItems.length"
                                    :current-store-file-category-id="storeFileCategoryId"
                                    @confirm-move="onConfirmMoveItemsClick"
                                    @cancel="showMoveDialog = false"/>
        </template>
      </v-col>
    </v-row>
    <v-row class="mt-0 px-2">
      <v-col>
        <v-row>
          <v-window
              v-model="pagination.loadedPage"
              style="width: 100%"
          >
            <v-window-item
                v-for="n in pagination.totalPages"
                :key="`card-${n}`"
            >
              <v-row class="pa-6">
                <v-col cols="2" v-for="file in batch[n-1]" :key="file.id">
                  <v-row no-gutters justify="end" v-if="!inSelectionMode">
                    <v-btn icon
                           tile
                           color="white"
                           class="black"
                           small
                           style="z-index: 1"
                           @click="openFile(file)">
                      <v-icon small>$download</v-icon>
                    </v-btn>
                    <v-btn icon
                           tile
                           color="white"
                           class="error"
                           small
                           style="z-index: 1"
                           @click.prevent="deleteFile(file)">
                      <v-icon small>$delete</v-icon>
                    </v-btn>
                  </v-row>
                  <v-responsive :aspect-ratio="1"
                                class="justify-center align-center cursor-pointer text-center"
                                :class="{ selected: file.selected}"
                                @click="inSelectionMode ? file.selected = !file.selected : openFile(file)">
                    <div class="selection-icon" v-if="inSelectionMode">
                      <v-icon color="primary" v-if="file.selected">$checkRoundChecked</v-icon>
                      <v-icon color="primary" v-else>$checkRoundUnchecked</v-icon>
                    </div>
                    <v-img :src="`${file.url}&type=thumbnail`"
                           :aspect-ratio="1"
                           min-height="100%"
                           min-width="100%"
                           :lazy-src="`${file.url}&type=lazy`"
                           v-if="file.type && file.type.match('image/*')"/>
                    <div v-else>
                      <v-icon class="mb-3" large>{{ getFileTypeIcon(file.type) }}</v-icon>
                      <div class="text-body-2 text-truncate" :title="file.name">{{ file.name }}</div>
                    </div>
                  </v-responsive>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-row>
        <v-row class="justify-center" v-if="pagination.totalPages > 1">
          <v-item-group
              v-model="pagination.requestedPage"
              class="shrink mr-6"
              mandatory
              @change="onPaginateClick"
          >
            <v-item
                v-for="n in pagination.totalPages"
                :key="n"
                v-slot="{ active, toggle }"
            >
              <v-btn
                  :input-value="active"
                  icon
                  @click="toggle"
              >
                <v-icon small>$circle</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { batch, removeFile, removeFileBulk, moveFileBulk } from '@/modules/locationDetails/api/locationDetails.js';
import { get } from '@/application/api/implementations/app';
import downloadFile from '@/application/util/downloadFile.js';
import KBtn from '@/components/KButton.vue';
import DropdownButton from '@/components/DropdownButton.vue';
import { EventBus } from '@/application/EventBus.js';
import MoveFilesToCategoryModal from '@/modules/locationDetails/components/MoveFilesToCategoryModal.vue';

export default {
  name: 'FileBatch',
  components: {
    MoveFilesToCategoryModal,
    DropdownButton,
    KBtn,
  },
  props: {
    fileBatchId: {
      type: Number,
      required: true,
    },
    storeFileCategoryId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    inSelectionMode: false,
    showMoveDialog: false,
    batch: [],
    disabled: false,
    pagination: {
      requestedPage: 0,
      loadedPage: 0,
      totalPages: 1,
      totalFiles: 0,
    },
  }),
  computed: {
    selectedItems() {
      const allItems = [].concat.apply([], this.batch);
      return allItems.filter(item => item.selected);
    },
  },
  watch: {
    fileBatchId: {
      immediate: true,
      handler() {
        this.fetchBatch();
      },
    },
  },
  methods: {
    onPaginateClick() {
      this.fetchBatch();
    },
    cancelSelectionMode() {
      this.deSelectAll();
      this.inSelectionMode = false;
    },
    async selectAll() {
      for (let i = 0; i < this.pagination.totalPages; i++) {
        this.pagination.requestedPage = i;
        await this.fetchBatch();
        this.batch[i].map(file => file.selected = true);
      }
    },
    deSelectAll() {
      this.selectedItems.map(file => file.selected = false);
    },
    openMoveItemsModal() {
      this.showMoveDialog = true;
    },
    async onConfirmMoveItemsClick(categoryId) {
      try {
        await moveFileBulk(this.fileBatchId, categoryId, this.selectedItems.map(item => item.id));
        this.showMoveDialog = false;
        EventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('locationDetails.actions.move.success'),
        });
        this.$emit('change');
      } catch {
        EventBus.$emit('snackbar', {
          color: 'red',
          text: this.$t('locationDetails.actions.move.noSuccess'),
        });
      }
    },
    openConfirmDeleteModal() {
      EventBus.$emit('confirm', {
        title: this.$t('locationDetails.actions.delete.confirmationTitle', { selectedAmount: this.selectedItems.length }),
        body: this.$t('locationDetails.actions.delete.confirmationBody', { selectedAmount: this.selectedItems.length }),
        confirmCallback: async () => {
          try {
            await removeFileBulk(this.fileBatchId, this.selectedItems.map(file => file.id));
            EventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('locationDetails.actions.delete.success'),
            });
            this.$emit('change');
          } catch (e) {
            EventBus.$emit('snackbar', {
              color: 'red',
              text: this.$t('locationDetails.actions.delete.noSuccess'),
            });
          }
        },
      });
    },
    async fetchBatch() {
      if (!this.batch[this.pagination.requestedPage]) {
        const response = await batch(this.fileBatchId, (this.pagination.requestedPage + 1), 6);
        // add prop for selection functionality
        const files = response.data.data.map(file => {
          return Object.assign({}, file, { selected: false });
        });
        this.$set(this.batch, this.pagination.requestedPage, files);
        this.pagination.totalPages = response.data.meta.lastPage;
        this.pagination.totalFiles = response.data.meta.total;
      }
      this.pagination.loadedPage = this.pagination.requestedPage;
    },
    async openFile(file) {
      if (!this.disabled) {
        if (this.fileIsImage(file.type)){
          EventBus.$emit('expandImage', { imgUrl: file.url });
          return;
        }

        this.disabled = true;
        await downloadFile(get(file.url, { responseType: 'blob' }), file.name);
        this.disabled = false;
      }
    },
    async deleteFile(file) {
      if (!this.disabled) {
        this.disabled = true;
        await removeFile(this.fileBatchId, file.id);
        await this.$emit('change');
        this.disabled = false;
      }
    },
    fileIsImage(type) {
      if (type && type.match('image/*')) {
        return true;
      }
      return false;
    },
    getFileTypeIcon(type) {
      if (!type) {
        return '$fileUnknown';
      }
      if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(type)) {
        return '$fileWord';
      }
      if (['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(type)) {
        return '$fileExcel';
      }
      if (type.match('video.*')) {
        return '$fileVideo';
      }
      if (['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(type)) {
        return '$filePowerpoint';
      }
      if (type === 'application/pdf') {
        return '$filePdf';
      }
      return '$fileUnknown';

    },
  },
};
</script>

<style scoped lang="scss">
// @TODO https://github.com/vuetifyjs/vuetify/issues/11771
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}

// @TODO this is a vuetify bug, when you remove this on transitioning the height is fluctuating
::v-deep .v-window__container {
  height: auto !important;
}

.position-relative {
  position: relative;
}

.selected {
  border: 4px solid #69be28;
}

.selection-icon {
  position: absolute;
  z-index:  1;
  top:      10px;
  right:    10px;
}
</style>
